
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "StripePaymentMethods",
  data() {
    return {
      cardTypeOptions: [
        { label: "Debit Card", value: "visa", icon: "visa" },
        { label: "Debit Card", value: "mastercard", icon: "mastercard" }
      ] as any,
      btnDisabled: ""
    };
  },
  computed: {
    ...mapGetters({
      paymentMethods: "stripePayment/getPaymentMethods"
    })
  },
  created(): void {
    this.$store.dispatch("stripePayment/GET_PAYMENT_METHODS");
  },
  methods: {
    getIconType(type: string) {
      const index = this.cardTypeOptions.findIndex(
        (i: any) => i.value === type
      );
      if (index > -1) {
        return this.cardTypeOptions[index].icon;
      }
      return "visa";
    },
    deletePaymentMethod(id: string) {
      this.btnDisabled = id;
      this.$store
        .dispatch("stripePayment/DELETE_PAYMENT_METHOD", id)
        .then(() => {
          this.btnDisabled = "";
        });
    }
  }
});
